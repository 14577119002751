import * as React from "react"
import { Link } from 'gatsby';
import { useEffect, useState } from "react";
import useHttp from '../../hooks/use-http';
import { getGSheetsEvents } from '../../api/GSheetsEvents';
import { formatInTimeZone } from 'date-fns-tz';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import Layout from "../../templates/layout-standard"
import Seo from "../../components/seo"
import SideBar from '../../components/sidebar';
import SpeakWithAdvisor from '../../components/ui/SpeakWithAdvisor';
import calendarsecondaryicn from '../../assets/img/calendar-secondary-icn.svg';

const stateAndCounty = [
	['NY','Clinton'],
	['NY','Essex'],
	['NY','Franklin'],
	['NY','Hamilton'],
	['NY','St. Lawrence'],
	['VT','Addison'],
	['VT','Bennington'],
	['VT','Caledonia'],
	['VT','Chittenden'],
	['VT','Essex'],
	['VT','Franklin'],
	['VT','Grand Isle'],
	['VT','Lamoille'],
	['VT','Orange'],
	['VT','Orleans'],
	['VT','Rutland'],
	['VT','Washington'],
	['VT','Windham'],
	['VT','Windsor']
];

function toTitleCase(str) {
	return str.replace(
		/\w\S*/g,
		function(txt) {
		return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
		}
	);
}

const AttendASeminar = ({ props, location }) => {

	let output = '';
	const [selectedCounty, setSelectedCounty] = useState(''); 
	let { sendRequest, status, data, error } = useHttp(getGSheetsEvents, true);

	let params = new URLSearchParams(location.search);
	let urlCounty = params.get("county");
	let urlState = params.get("state");

	function handleCountyChange(value) {
		setSelectedCounty(value);
	}

	useEffect(() => {
		if (urlCounty && urlState) {
			const filteredUrlState = urlState.toUpperCase();
			const filteredUrlCounty = toTitleCase(urlCounty);
			if (stateAndCounty.some(e => e[0] === filteredUrlState) && stateAndCounty.some(e => e[1] === filteredUrlCounty)) {
				setSelectedCounty(filteredUrlState + filteredUrlCounty);
			}
		}
	}, []);

	useEffect(() => {
		const chosenCounty = {
			state: selectedCounty.slice(0, 2),
			county: selectedCounty.substring(2)
		}
		sendRequest(chosenCounty);
	}, [selectedCounty]);

	if (status == 'pending' && selectedCounty) { 
		output = <p><LoadingSpinner /></p>;
	} else if (error !== null) {
		return <p>Error: {error}</p>
	} else if (status === 'completed') {
		if ( !data || !Array.isArray(data) || data.length === 0 ) {
			if (selectedCounty) {
				output = <h4 className="noevents">There are no seminars scheduled at this time. Call 1-833-368-4592 (TTY 711) to speak with a UVM Health Advantage Plan Guide, or search available <Link to="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling@mvphealthcare.onmicrosoft.com/bookings/">in-person appointments</Link> or <Link to="/meet-with-us/request-consultation/">request a consultation</Link> at a time and place that is convenient for you.</h4>
			} else {
				output = '';
			}
		} else {
			output = data.map((event) => {

				const eventDateTime = event[1] + " " + event[2];
				const eventDate = new Date(eventDateTime);
				const eventFormat = event[4];

				return (
					<div key={event[16]} className="eventitem">
						<time dateTime={formatInTimeZone(eventDate, 'America/New_York', 'MMMM d, yyyy, h:mma')}>{formatInTimeZone(eventDate, 'America/New_York', 'MMMM d, yyyy, h:mma')} ({eventFormat})</time>
						<div>
							<h3 className="small">{event[0]}</h3>
							{ event[8] && (
								<p className="eventLocation"> 
									{ event[8] }<br />
									{ event[9] && event[9] } 
								</p>
							)  }
							<p className="description" dangerouslySetInnerHTML={{ __html: event[3] }} />
							<Link to={'/meet-with-us/attend-a-seminar/detail/?event=' + event[16]}>Register Now</Link>
						</div>
					</div>
				)

			});
		}
	}
	
	return (
		<Layout location={location} heading1="Meet With Us" heading2="We&rsquo;re here to help.">
			<Seo title="Attend a Free Medicare Seminar" meta={[{description: 'Get the latest information so you can make confident decisions about your coverage.'}]} bodyclass="meet-with-us attend-a-seminar subpage" />
			
			<div className="constrained">
				<div className="content-constrained">
					<div className="flexwrap_subpage">
					
						<main>
							<h3>Attend a Free Medicare Seminar</h3>
							<p>UVM Health Advantage Plan Guides are a great resource. So let&rsquo;s work together! Learn the basics of Medicare or explore the plans available to you. Get the latest information so you can make confident decisions about your coverage.</p>
							<p>For accommodation of persons with special needs at sales meetings call 1-833-368-4592 (TTY 711).</p>

							<label className="visuallyhidden" htmlFor="countySelect">Select Your County:</label>
							<select id="countySelect" name="selectedcounty" value={selectedCounty} onChange={e => handleCountyChange(e.target.value)}>
								<option value="">Select Your County</option>
								{stateAndCounty.map(statecounty => (
									<option key={statecounty[0] + statecounty[1]} value={statecounty[0] + statecounty[1]}>{statecounty[0] + " - " + statecounty[1]}</option>
								))}
							</select>

							{output}

							<div className="ctas secondary">						
								<div className="flexwrap">
									<div className="secondary-cta">
										<img src={calendarsecondaryicn} alt="Schedule a personal consultation with an UVMHA Medicare Plan Guide" width="60" height="60" className="icon" />
										<p className="head"><b>Schedule a personal consultation with an UVMHA Medicare Plan Guide.</b></p>
										<p>Search available phone, video, or in-person appointments, or request a meeting at a time and place that&rsquo;s convenient for you.</p>
										<a href="https://outlook.office365.com/owa/calendar/UVMHealthAdvantagePlanConsultationScheduling%40mvphealthcare.onmicrosoft.com/bookings/" className="button external_button" target="_blank" rel="noreferrer">Search Appointments</a>
										<Link to="/meet-with-us/request-consultation/" className="button secondary">Request a Consultation</Link>
									</div>
								</div>
							</div>

							{/* <p className="disclaimer">UVM Health Advantage is aware that safety guidelines from federal and state agencies regarding COVID-19 have been evolving. At this time, face masks are not required while on UVM property. Disposable masks will be made available if needed. Please refer to <a href="https://cdc.gov" className="external">CDC.gov</a> for the latest information.</p> */}

							<SpeakWithAdvisor />
						</main>

						<SideBar location={location} />
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default AttendASeminar
